




























































































import Vue from "vue";
import weui from "weui.js";
import "weui";
import { Toast, Popup, Picker } from "vant";

export default Vue.extend({
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker
  },
  data() {
    return {
      agencyId: 0,
      mode: 1,
      agencyName: "",
      name: "",
      phone: "",
      servicePhone: "",
      businessPicker: {
        show: false,
        list: [],
        name: "",
        id: 0,
        default: 0,
        loading: true
      }
    };
  },
  watch: {
    "businessPicker.show": "onBusinessPickerShow"
  },
  methods: {
    save() {
      const reg = /^[0-9]{11}$/;
      if (this.agencyId < 1) {
        weui.alert("请先通过查询按钮获取电宝运营商信息");
        return;
      }
      if (!reg.test(this.phone)) {
        weui.alert("手机号格式不对");
        return;
      }
      if (!this.name || !this.phone) {
        weui.alert("内容未完整填写");
        return;
      }
      const data = {
        agencyId: this.agencyId,
        name: this.name,
        phone: this.phone,
        servicePhone: this.servicePhone,
        businessId: this.mode == 2 ? this.businessPicker.id : 0
      };
      this.$axios.post("/api/manage/business/transferAgencyFromDianbao", data).then(() => {
        Toast.success("迁移成功");
      });
    },
    findAgency() {
      if (!this.agencyName) {
        Toast.fail("请先填写电宝运营商名称");
        return;
      }
      this.$axios.post("/api/manage/business/findAgencyFromDianbao", { name: this.agencyName }).then(res => {
        const data = res.data.data;
        this.agencyId = data.id;
        this.name = data.name;
        this.phone = data.mobile;
        this.servicePhone = data.mobile;
      });
    },
    onBusinessPickerShow(show: boolean) {
      if (show && this.businessPicker.loading) {
        this.$axios.post("/api/manage/getBusinessListForPick").then(res => {
          this.businessPicker.list = res.data.data;
          this.businessPicker.loading = false;
        });
      }
    },
    onPickBusiness(value: { id: number; name: string }) {
      this.businessPicker.name = value.name;
      this.businessPicker.id = value.id;
      this.businessPicker.show = false;
    }
  }
});
